@import "colors.scss";

html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    -ms-overflow-style: none!important;
}
@-ms-viewport {
    width:auto!important
}

pre {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
}

a {
    cursor: pointer;
}

* {
    word-wrap: break-word;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="time"],
input[type="date"] {
    line-height: normal;
}

.block {
    background-color: rgba(125, 125, 125, 0.25);
    z-index: 10000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;

    .center {
        display: table;
        width: 100%;
        height: 100%;

        > div {
            display: table-row;
            width: 100%;
            height: 100%;

            > div {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }
    }
}

.opaque {
    background-color: rgba(255, 255, 255, 1);
}

#login {
    height: 100%;

    .login-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .login-form {
            width: 100%;
            overflow: hidden;

            text-align: center;

            .login-form-inlet {
                display: inline-block;
                width: 320px;
                height: 100%;
                padding: 8px 0;
            }

            .login-form-table {
                display: table;
                width: 100%;
                height: 100%;

                > div {
                    display: table-row;

                    > div {
                        display: table-cell;
                    }
                }
            }

            form {
                input[type=text],
                input[type=password] {
                    width: 100%;
                    padding: 6px 3px;
                    border: 1px solid $default-border;
                }

                input:focus,
                input:hover {
                    border: 1px solid $red;
                    box-shadow: none;
                }
            }

            .login-button {
                width: 100%;
            }

            .login-remember {
                padding: 4px 0px;
                text-align: center;

                > label {
                    position: relative;
                    top: -2px;
                    font-weight: normal;
                }
            }
        }

        .login-form.no-users {
            height: 100%;
        }

        .login-users {
            width: 100%;
            padding: 16px;

            text-align: center;

            overflow: auto;
            -webkit-overflow-scrolling: touch;

            .user-block {
                display: inline-block;
                border: 1px solid transparent;
                cursor: pointer;
                margin: 6px;
            }

            .user-block:hover {
                border: 1px solid $red;
            }
        }
    }
}

.navbar-header {
    white-space: nowrap;
}

.navbar-title,
.navbar-title:hover,
.navbar-title:active,
.navbar-title:focus,
.navbar-title:visited {
    text-decoration: none;
}

.navbar-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-variant: small-caps;
    font-size: 20px;

    > div {
        display: inline-block;
        vertical-align: middle;
    }

    #logo {
        margin: 0 10px;
        margin-top: -2px;

        width: 161px;
        height: 28px;

        background-image: url('../images/USIM_Titel_Logo.png'); /* this path is relative to the css file */
        background-size: 100%;
        background-repeat: no-repeat;
    }

    #logo2 {
        margin: 0 10px;

        width: 114px;
        height: 28px;

        background-image: url('../images/logo_small2.png'); /* this path is relative to the css file */
        background-size: 100%;
        background-repeat: no-repeat;
    }
}

.fill-space {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.fill-space-horizontal {
    position: absolute;
    left: 0px;
    right: 0px;
}

.table-row {
    display: table-row;
}
.table-row > * {
    display: table-cell;
}
.table-row.centered > * {
    vertical-align: middle;
}

.sidebar-left {
    .sidebar-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 20px;

        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    .sidebar-inlet-bottom {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;

        height: 20px;
        padding: 2px 4px;
        font-size: 10px;

        border-top: 1px solid $default-border;

        > div:nth-child(1) {
            float: left;
        }

        > div:nth-child(2) {
            float: right;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        a:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.about-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20px;

    padding: 0px 8px;
    
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    
    .about-header {
        white-space: nowrap;
        overflow-x: hidden;
        
        > * {
            display: inline-block;
        }
    }
}

.about-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    
    height: 20px;
    padding: 2px 4px;
    
    white-space: nowrap;
    overflow: hidden;
    
    background-color: #f5f5f5;
    border-top: 1px solid $default-border;
    
    > div {
        text-align: right;
        font-size: 10px;
        color: $grey;
    }
}

.entry-title {
    font-size: 16px;

    white-space: nowrap;
}

.entry-addendum {
    width: 100%;
}

.entry-left {
    white-space: nowrap;
    overflow: hidden;

    max-width: 48%;
}

.entry-right {
    white-space: nowrap;
    overflow: hidden;

    text-align: right;
    padding-right: 3px!important;

    max-width: 48%;
}

.error-name {
    font-weight: bold;
    white-space: nowrap;
}

h2 .title-info-small {
    font-size: 18px;
}

.detail {
    .content-name {
        white-space: nowrap;
        overflow: hidden;

        text-align: right;
        font-variant: small-caps;

        max-width: 50%;
    }
    
    .content-label {
        white-space: nowrap;
        overflow: hidden;
        
        font-variant: small-caps;
        font-size: 12px;
    }

    .content-label a {
        text-decoration: underline;
    }

    .property-title {
        padding: 8px;
        text-align: center;
    }

    .content-cell {
        position: relative;
        padding: 0;

        > * {
            padding: 4px 0 4px 8px;
        }
    }
    
    .content-text {
        position: relative;
        margin-bottom: 8px;
        font-size: 16px;
    }

    input[type="radio"] {
        margin-right: 5px;
    }

    input[type="radio"]:first-child + span {
        margin-right: 50px;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="time"],
    input[type="date"],
    select,
    textarea {
        width: 100%;
        margin: 0;
        border: 0;

        border-radius: 0;
        box-shadow: 0 0;
    }

    textarea {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .bi-wrapper {
        position: relative;
        min-height: 32px;

        .bi-clear {
            position: absolute;
            right: 32px;
            top: 4px;
            z-index: 10;
            
            padding: 1px 4px;
            
            border: 1px solid $default-border;
            border-radius: 0;
        }
        
        .bi-info {
            color: $grey;
        }

        input[type=text] {
            padding: 0;
        }
    }

    .mc-wrapper {
        position: relative;

        > div {
            margin-top: 4px;
        }
    }
    
    .bi-wrapper .bi-search,
    .mc-wrapper .bi-search {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 10;

        padding: 1px 4px;
        
        border: 1px solid $default-border;
        border-radius: 0;
    }

    .inline-search {
        padding-right: 30px;
    }

    .edit .alert {
        padding-top: 0px;
        padding-bottom: 1px;

        position: absolute;
        margin-top: -1px;
        right: 0;
        top: 0;

        z-index: 100;
    }
    .alert-error {
        background-color: #F2DEDE;
        border-color: #EED3D7;
        color: #B94A48;
    }

    .float-value {
        width: 150px;
        text-align: right;
    }
}

.bi-search-info {
    padding: 8px 0;
    text-align: right;

    > div {
        display: inline-block;
        padding-top: 6px;
        padding-right: 6px;
    }

    > button {
        float: right;
        border: 1px solid $default-border;
    }
}

.edit {
    .detail-buttons {
        border-bottom: 1px solid $default-border;

        position: absolute;
        left: 0px;
        right: 0px;

        height: 34px;
        margin-top: -1px;
    }

    .detail-edit {
        position: absolute;
        top: 33px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.follow-table {
    display: table;
    cursor: pointer;
    width: 100%;

    > div {
        display: table-row;

        > div {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            padding: 10px 15px;

            font-variant: small-caps;

            border-bottom: 1px solid $default-border;
        }

        > div.ft-pull-right {
            width: 34px;
            text-align: center;
        }
    }
}

.html-view {
    padding: 4px;
}
.html-small {
    padding: 4px;
}

.html-view-debug {
    z-index: 500;
    background-color: rgba(200, 200, 200, 0.95);
    position: relative;
}

.edit-element {
    display: inline-block;
    cursor: pointer;
}

/* PSEUDO TABLES */

.table thead > tr > th {
    vertical-align: top;
}

.pseudo-table {
    display: table;
    table-layout: fixed;

    > div {
        display: table-row;

        > span {
            display: table-cell;

            vertical-align: top;

            overflow: hidden;
        }

        > span:last-child {
            border-right: none;
        }
    }

    > div:last-child > span {
        border-bottom: none;
    }

    > div.line > span {
        border-top: 1px solid $default-border;
        border-bottom: none;
    }

    > div.no-line > span {
        border-top: none;
        border-bottom: none;
    }

    > div.align-right > span {
        text-align: right;
    }
}

.no-border {
    border: none;
}
.pseudo-table.no-border > div > span {
    border: none;
}

.pseudo-table.no-padding > div > span {
    padding: 0;
}

#biModal table {
    th, td {
        white-space: nowrap;
    }
}

/* Upload button */

.file-pick input {
    display: block !important;
    opacity: 0 !important;
    height: 100% !important;
    width: 100% !important;
    cursor: pointer;
}

.file-pick div {
    color: $black;
    opacity: 1;
    display: block;
    overflow: hidden;
    text-align: center;

    position: relative;
    left: 0;
    top: -30px;

    height: 100%;
    padding-top: 4px;

    pointer-events: none;
}

.file-drop {
    width: 100%;
    border: 4px dashed $default-border;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    color: $default-border;
}

/* Upload modal */

.upload-modal-form {
    padding: 8px;
}

.file-pick {
    width: 100%;
    height: 30px;
}

/* Reports */

.report-tree ul {
    list-style: none;
}
.report-tree ul:first-child {
    padding-left: 0;
}

.report-tree .folder-title {
    padding: 8px;
    font-weight: bold;
    cursor: pointer;
}

.report-tree .folder-content {
    padding-left: 20px;
}

.report-tree .pseudo-table {
    border: 1px solid $default-border;
}

/* keys */

.key {
    background-color: #ECECEC;
    border: 1px solid $default-border;
    border-radius: 4px;

    display: inline-block;
    height: 24px;
    min-width: 24px;
    padding: 0 4px;

    text-align: center;
}

/* time directive */

.day-summary {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 20px;

    text-align: center;
    padding: 0px 5px;

    font-size: 11px;
    overflow: hidden;
}
.day-summary span:nth-child(1) {
    float: left;
}
.day-summary span:nth-child(3) {
    float: right;
}

.calendar-holder {
    z-index: 450;
    background-color: white;
}

.datepicker {
    td,
    th {
        border-radius: 0;
    }
    
    td:hover,
    .active {
        border-radius: 4px;
    }
}

/* Chart Fix */

div.graph.c3 {
    z-index: -1;
}