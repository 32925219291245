$red: #e1192c;
$text-color-red: white;

$grey: #606060;
$text-color-grey: white;

$light: #c0c0c0;
$text-color-light: black;

$ultra-light: #f0f0f0;
$ultra-light-text-color: black;

$orange: #de8d00;
$text-color-orange: black;

$yellow: #fbd046;
$green: #a1c057;
$blue: #3e9bd5;
$lila: #c18dbf;

$black: #000000;
$text-color-black: white;

$white: #ffffff;
$text-color-white: black;

$default-border: #cccccc;